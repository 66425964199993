import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch as AInstantSearch,
  Configure,
} from 'react-instantsearch-hooks-web';

export const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID || 'YB55O4F5UU',
  process.env.REACT_APP_ALGOLIA_API_KEY || '81f3433d75f89a9454dbb317d3666cdd'
);

export const InstantSearch = ({ indexName, facetFilters, children }) => {
  return (
    <AInstantSearch searchClient={searchClient} indexName={indexName}>
      <Configure hitsPerPage={30} facetFilters={facetFilters}/>
      {children}
    </AInstantSearch>
  );
};

export const searchData = async (indexName, query, options) => {
  const index = searchClient.initIndex(indexName);
  const result = await index.search(query, options);
  return result;
};

export const searchAttributes = async (indexName, facetName) => {
  const index = searchClient.initIndex(indexName);
  const result = await index.searchForFacetValues(facetName, '', {
    maxFacetHits: 100,
    maxValuesPerFacet: 200,
  });
  return result;
};

export const getNurseryList = async (options) => {
  // const facet = await searchAttributes('nursery_index', '농가명');
  const index = searchClient.initIndex('nursery_index');
  const result = await index.search('', {
    facetFilters: options?.uid ? `uid:${options.uid}` : undefined,
    facets: ['농가명'],
    sortFacetValuesBy: 'alpha',
    maxValuesPerFacet: 1000,
    hitsPerPage: 1000,
  },)
  const facetHits = Object.keys(result.facets.농가명)

  const list = await Promise.all(
    facetHits.map(async (obj, index) => {
      const nursery = {};
      const data = await searchData('nursery_index', obj);
      const { hits } = data;
      const { objectID, 연락처: phone } = hits[0];
      nursery.name = obj;
      nursery.objectID = objectID;
      nursery.phone = phone;
      return nursery;
    })
  );
  return list;
};

export const getLandList = async (_nursery_objectID) => {
  const index = searchClient.initIndex('land_index');
  const option = _nursery_objectID
    ? {
        filters: `nursery_objectID:${_nursery_objectID}`,
        maxFacetHits: 100,
      }
    : {};
  const { facetHits } = await index.searchForFacetValues(
    '필지주소',
    '',
    option
  );

  const list = await Promise.all(
    facetHits.map(async (obj, index) => {
      const land = {};
      const data = await searchData('land_index', obj.value);
      const { hits } = data;
      const { objectID, 필지명: landName } = hits[0];
      land.name = obj.value;
      land.landName = landName;
      land.objectID = objectID;
      return land;
    })
  );
  return list;
};

export const getTreeTypeList = async (_land_objectID) => {
  const index = searchClient.initIndex('tree_index');
  const option = _land_objectID
    ? {
        filters: `land_objectID:${_land_objectID}`,
      }
    : {};
  const { facetHits } = await index.searchForFacetValues('수종', '', option);
  return facetHits;
};

export const getTreeShapeList = async () => {
  const index = searchClient.initIndex('tree_index');
  const { facetHits } = await index.searchForFacetValues('수형', '');
  return facetHits;
};

export const getLandMainList = async () => {
  const facet = await searchAttributes('land_index', '주력분야');
  const { facetHits } = facet;
  return facetHits;
};

export const getNurseryMainList = async () => {
  const facet = await searchAttributes('nursery_index', '주요수종');
  const { facetHits } = facet;
  return facetHits;
};
