import React from 'react';
import { InstantSearch } from '../../common/algoliasearch';
import LandForm from '../../components/LandForm';
import { useLoaderData } from 'react-router-dom';
import { useFuro } from 'furo-react';
import { isSeller } from '../../common/env';

const LandFormWrapper = () => {
  const { user } = useFuro();
  return (
    <InstantSearch
      indexName='land_index'
      facetFilters={isSeller() ? `uid:${user?.uid}` : undefined}
    >
      <LandForm />;
    </InstantSearch>
  );
};

export default LandFormWrapper;
