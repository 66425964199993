import React, { useEffect, useState } from 'react';
import { Form, Select, Input, notification } from 'antd';
import Button from './Button';
import InputNumber from './InputNumber';
import InputAddress from './InputAddress';
import DatePicker from './DatePicker';
import UploadImages from './UploadImages';
import { isMobile } from 'react-device-detect';
import { getAddress, registerLand } from '../api';
import { useNavigate, useLoaderData } from 'react-router-dom';
import { onInputPhoneNumber } from '../utils/utils';
import {
  getLandMainList,
  getNurseryList,
  getTreeTypeList,
} from '../common/algoliasearch';
import CustomDropdownRender from './CustomDropdownRender';
import { useFuro } from 'furo-react';
import { isSeller } from '../common/env';

const { Option } = Select;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    span: isMobile ? 24 : 6,
  },
  wrapperCol: {
    span: isMobile ? 24 : 18,
  },
};
const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
const LandForm = (props) => {
  const navigate = useNavigate();
  const initialValues = useLoaderData();

  const { user } = useFuro();

  const [form] = Form.useForm();
  const [nursery, setNursery] = useState();
  const [nurseryList, setNurseryList] = useState([]);
  const [treeTypeList, setTreeTypeList] = useState([]);
  const [landMain, setLandMain] = useState();
  const [landMainList, setLandMainList] = useState([]);
  const [landMainOpen, setLandMainOpen] = useState();

  useEffect(() => {
    (async () => {
      const nurseryList = await getNurseryList({
        uid: isSeller() ? user.uid : undefined,
      });
      setNurseryList(nurseryList);
      const landMainList = await getLandMainList();
      setLandMainList(landMainList);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const list = await getTreeTypeList();
      setTreeTypeList(list);
    })();
  }, []);

  const onFinish = async (values) => {
    console.log('Received values of form: ', values);
    // objectID 같은것도 포함해줘야 한다
    const data = await registerLand({ ...initialValues, ...values, uid: user.uid });
    console.log(data);
    notification.success({
      message: '등록 성공',
    });
    navigate('/lands');
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    notification.error({
      message: '오류가 발생했습니다',
      description: `아래 항목을 채워주세요: ${errorInfo.errorFields.map(
        (error) => error.name
      )}`,
    });
  };

  const [address, setAddress] = useState();

  const handleChange = (value) => {
    const nursery = nurseryList.find((n) => n.name === value);
    form.setFieldsValue({
      nursery_objectID: nursery.objectID,
    });
    setNursery(nursery);
  };

  return (
    <>
      <Form
        form={form}
        name='land'
        {...formItemLayout}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout={isMobile ? 'vertical' : 'horizontal'}
        size='large'
        style={{ maxWidth: 480, margin: 'auto' }}
        initialValues={{
          ...initialValues,
          // 최근방문일: new Date(initialValues.최근방문일),
          농가연락처: nursery && nursery.phone,
          최근방문일: null,
        }}
      >
        {/* <div style={{ fontSize: 18, fontWeight: '600' }}>농가 정보</div> */}
        {/* <div style={{ fontSize: 24, fontWeight: '600' }}>필지 정보</div> */}
        {/* 농가명 */}
        <Form.Item
          name='농가명'
          label='농가명'
          hasFeedback
          rules={[
            {
              required: true,
              message: '농가를 선택해주세요!',
            },
          ]}
        >
          <Select placeholder='농가를 선택해주세요' onChange={handleChange}>
            {nurseryList &&
              nurseryList.length > 0 &&
              nurseryList.map((nursery, index) => (
                <Option key={index} value={nursery?.name}>
                  {nursery?.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        {/* 농가가 선택되면 아래 농가 프리뷰 보여줄것 */}
        {/* 농가 objectID*/}
        <Form.Item name='nursery_objectID' hidden />
        {/* 필지명 */}
        <Form.Item name='필지명' label='필지명'>
          <Input />
        </Form.Item>
        {/* 필지 주소 */}
        <Form.Item name='필지주소' label='필지 주소' extra={address}>
          <InputAddress
            callback={async ({ latitude, longitude }) => {
              setAddress(`latitude: ${latitude}, longitude: ${longitude}`);
              const addr = await getAddress({ lat: latitude, lng: longitude });
              const result =
                addr.formatted_address.split('대한민국 ')[1].length > 5
                  ? addr.formatted_address.split('대한민국 ')[1]
                  : addr.formatted_address;
              form.setFieldValue('필지주소', result);
              return result;
            }}
            form={form}
          />
        </Form.Item>
        {/* 주력 분야 */}
        <Form.Item name='주력분야' label='주력 분야'>
          <Select
            showSearch
            placeholder='주력분야를 선택해주세요'
            value={landMain}
            open={landMainOpen}
            onDropdownVisibleChange={(visible) => setLandMainOpen(visible)}
            onSearch={(value) => setLandMain(value)}
            options={landMainList.map((item) => ({
              label: item.value,
              value: item.value,
            }))}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setLandMainOpen(false);
                form.setFieldValue('주력분야', landMain);
              }
            }}
            dropdownRender={(menu) => (
              <CustomDropdownRender
                menu={menu}
                input={landMain}
                onClick={() => {
                  setLandMainOpen(false);
                  form.setFieldValue('주력분야', landMain);
                }}
              />
            )}
          />
        </Form.Item>
        {/* 필지 수종 */}
        <Form.Item
          name='필지수종'
          label='필지 수종'
          rules={[
            {
              // required: true,
              message: '주요 수종을 선택해주세요!',
              type: 'array',
            },
          ]}
          extra='필지 내 모든 수종'
        >
          <Select
            mode='tags'
            placeholder='주요 수종를 선택해주세요'
            options={treeTypeList.map((item) => ({
              label: item.value,
              value: item.value,
            }))}
          />
        </Form.Item>
        {/* 필지 등급 */}
        <Form.Item name='필지등급' label='필지 등급'>
          <Select placeholder='등급을 선택해주세요'>
            <Option value='A'>A</Option>
            <Option value='B'>B</Option>
            <Option value='C'>C</Option>
            <Option value='D'>D</Option>
          </Select>
          {/* <Rate defaultValue={2} character={({ index }) => index + 1} /> */}
        </Form.Item>
        {/* 필지 면적 */}
        <Form.Item name='필지면적' label='필지 면적 (평)'>
          <InputNumber placeholder='120000' addonBefore='평' />
        </Form.Item>
        {/* 보유수목수량 */}
        <Form.Item name='보유수목수량' label='보유 수목 수량 (주)'>
          <InputNumber addonBefore='주' placeholder='7000' />
        </Form.Item>
        {/* 필지 판매가 */}
        <Form.Item name='필지판매가' label='필지 판매가 (원)'>
          <InputNumber placeholder='120000' addonBefore='₩' />
        </Form.Item>
        {/* 최근 방문일 */}
        <Form.Item name='최근방문일' label='최근 방문일'>
          <DatePicker placement='topRight' />
        </Form.Item>
        {/* 기타 메모 */}
        <Form.Item name='기타메모' label='기타 메모'>
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>
        {/* 촬영물 */}
        <Form.Item
          name='촬영물'
          label='촬영물'
          valuePropName='fileList'
          getValueFromEvent={normFile}
        >
          <UploadImages form={form} />
        </Form.Item>
        <Form.Item name='메타데이터' hidden />
        <Form.Item
          wrapperCol={{
            span: 24,
          }}
        >
          <Button type='primary' htmlType='submit' fill={'yes'} size='xl'>
            저장하기
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default LandForm;
