import React from 'react';
import { Button, notification, Popconfirm } from 'antd';
import { useParams, useLoaderData, useNavigate } from 'react-router-dom';
import TreeModal from '../components/TreeModal';

import trees from '../data/dummy';
import { InstantSearch } from '../common/algoliasearch';
import RequireAuth from '../common/RequireAuth';
import { useInstantSearch } from 'react-instantsearch-hooks-web';
import { deleteTree } from '../api';
import RestrictedView from '../components/RestrictedView';
import { ArrowLeftOutlined } from '@ant-design/icons'
import { isSeller } from '../common/env';

const TreeDetail = (props) => {
  const tree = useLoaderData();
  const { refresh } = useInstantSearch();
  const navigate = useNavigate();
  const onDeleteConfirm = async () => {
    if (!tree.objectID) notification.error({ message: 'Something wrong' });
    const response = await deleteTree(tree.objectID);
    if (!response) {
      notification.error({ message: 'Something wrong' });
    }
    notification.success({ message: '삭제중입니다.', duration: 2 });
    setTimeout(() => {
      refresh();
      navigate('/trees')
    }, 2000);
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          marginBottom: 12,
          justifyContent: 'space-between',
        }}
      >
        <Button type='link' onClick={() => window.history.back()}>
          <ArrowLeftOutlined/> Back to list
        </Button>
        <RestrictedView role={isSeller() && 'seller'}>
          <div
          style={{ display: 'flex', marginTop: 20, justifyContent: 'flex-end' }}
        >
          <Button
            style={{ minWidth: 80 }}
            href={`/trees/${tree.objectID}/edit`}
          >
            Edit
          </Button>
          <div style={{ width: 20 }} />
          <Popconfirm
            placement='topRight'
            title='삭제하시겠습니까?'
            okText='삭제'
            cancelText='취소'
            okButtonProps={{ danger: true }}
            onConfirm={onDeleteConfirm}
          >
            <Button danger style={{ minWidth: 80 }}>
              Delete
            </Button>
          </Popconfirm>
        </div>
        </RestrictedView>
        
      </div>
      <TreeModal tree={tree} />
    </>
  );
};

const TreeDetailWrapper = () => {
  return (
    <InstantSearch indexName='tree_index'>
      <TreeDetail />
    </InstantSearch>
  );
};

export default TreeDetailWrapper;
