import React, { useEffect, useState } from 'react';
import { Form, Select, Input, Rate, notification, Switch } from 'antd';
import Button from './Button';
import InputNumber from './InputNumber';
import DatePicker from './DatePicker';
import UploadImages from './UploadImages';
import InputAddress from './InputAddress';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { registerTree } from '../api';
import {
  getNurseryList,
  getLandList,
  getTreeTypeList,
  getTreeShapeList,
} from '../common/algoliasearch';
import { onInputPhoneNumber } from '../utils/utils';
import CustomDropdownRender from './CustomDropdownRender';
import { useFuro } from 'furo-react';
import { isSeller } from '../common/env';
const { Option } = Select;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
const TreeForm = () => {
  const navigate = useNavigate();
  const initialValues = useLoaderData();

  const { user } = useFuro();

  const [form] = Form.useForm();
  const [nursery, setNursery] = useState(initialValues?.농가명);
  const [nurseryList, setNurseryList] = useState([]);
  const [land, setLand] = useState();
  const [landList, setLandList] = useState([]);
  const [treeTypeList, setTreeTypeList] = useState([]);
  const [treeType, setTreeType] = useState();
  const [treeTypeOpen, setTreeTypeOpen] = useState(false);
  const [treeShapeList, setTreeShapeList] = useState([]);
  const [treeShape, setTreeShape] = useState();
  const [treeShapeOpen, setTreeShapeOpen] = useState(false);
  const [isSwitchChecked, setSwitchChecked] = useState(initialValues?.훈련여부);

  useEffect(() => {
    (async () => {
      const nurseryList = await getNurseryList({
        uid: isSeller() ? user.uid : undefined,
      });
      setNurseryList(nurseryList);
      const treeShapeList = await getTreeShapeList();
      setTreeShapeList(treeShapeList);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      if (!nursery) return;
      const landList = await getLandList(nursery.objectID);
      setLandList(landList);
    })();
  }, [nursery]);
  useEffect(() => {
    (async () => {
      if (!land) return;
      const list = await getTreeTypeList(land?.objectID);
      setTreeTypeList(list);
    })();
  }, [land]);

  useEffect(() => {
    if (!isSwitchChecked) {
      form.setFieldValue('훈련', undefined);
      form.setFieldValue('연차', undefined);
    }
  }, [isSwitchChecked]);

  const onFinish = async (values) => {
    console.log('Received values of form: ', values);
    // objectID 같은것도 포함해줘야 한다
    const data = await registerTree({ ...initialValues, ...values, uid: user.uid });
    console.log(data);
    notification.success({
      message: '등록 성공',
    });
    navigate('/trees');
  };
  const options = [];
  const handleNurseryChange = (value) => {
    const nursery = nurseryList.find((n) => n.name === value);
    form.setFieldsValue({
      농가연락처: nursery.phone,
      nursery_objectID: nursery.objectID,
    });
    setNursery(nursery);
  };
  const handleLandChange = (value) => {
    const land = landList.find((n) => n.name === value);
    form.setFieldsValue({
      land_objectID: land.objectID,
    });
    setLand(land);
  };
  const onSwitchChange = (value) => {
    setSwitchChecked(value);
  };
  return (
    <>
      <div style={{ fontSize: 24, fontWeight: '600' }}>수목 정보</div>
      <Form
        name='validate_other'
        form={form}
        {...formItemLayout}
        onFinish={onFinish}
        initialValues={{
          ...initialValues,
          농가: nursery,
          최근방문일: null,
        }}
        layout={'vertical'}
        size='large'
        style={{ maxWidth: 480, margin: 'auto' }}
      >
        {/* 농가명 */}
        <Form.Item
          name='농가명'
          label='농가명'
          hasFeedback
          rules={[
            {
              required: true,
              message: '농가를 선택해주세요!',
            },
          ]}
        >
          <Select
            placeholder='농가를 선택해주세요'
            onChange={handleNurseryChange}
            allowClear
          >
            {nurseryList &&
              nurseryList.length > 0 &&
              nurseryList.map((nursery, index) => (
                <Option key={index} value={nursery?.name}>
                  {nursery?.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        {/* 농가가 선택되면 아래 농가 프리뷰 보여줄것 */}
        {/* 농가 없으면 아래 다 없음 */}

        {nursery && (
          <>
            {/* 농가 연락처 */}
            {/* <Form.Item name='농가연락처' label='농가 연락처'>
              <Input
                onInput={onInputPhoneNumber}
                maxLength='13'
                placeholder='010-1234-5678'
              />
            </Form.Item> */}
            {/* 농가 objectID*/}
            <Form.Item name='nursery_objectID' hidden />
            {/* 필지 주소 */}
            <Form.Item
              name='필지주소'
              label='필지 주소'
              hasFeedback
              rules={[
                {
                  required: true,
                  message: '필지를 선택해주세요!',
                },
              ]}
            >
              <Select
                placeholder='필지를 선택해주세요'
                onChange={handleLandChange}
              >
                {landList &&
                  landList.length > 0 &&
                  landList.map((land, index) => (
                    <Option key={index} value={land?.name}>
                      {`${land?.name}${
                        land?.landName ? ` (${land.landName})` : ``
                      }`}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            {/* 필지 objectID*/}
            <Form.Item name='land_objectID' hidden />
            <Form.Item
              name='촬영물'
              label='촬영물'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <UploadImages form={form} />
            </Form.Item>
            <Form.Item name='메타데이터' hidden />
            <Form.Item name='위치' label='위치'>
              <InputAddress
                callback={async ({ latitude, longitude }) => {
                  const result = `${latitude}, ${longitude}`;
                  form.setFieldValue('위치', result);
                  return result;
                }}
              />
            </Form.Item>
            {/* 수종 */}
            <Form.Item
              name='수종'
              label='수종'
              // rules={[
              //   {
              //     required: true,
              //     message: '주요 수종을 선택해주세요!',
              //   },
              // ]}
              extra='필지 내 모든 수종'
            >
              <Select
                showSearch
                placeholder='수종를 선택해주세요'
                value={treeType}
                open={treeTypeOpen}
                onDropdownVisibleChange={(visible) => setTreeTypeOpen(visible)}
                onSearch={(value) => setTreeType(value)}
                options={treeTypeList.map((item) => ({
                  label: item.value,
                  value: item.value,
                }))}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setTreeTypeOpen(false);
                    form.setFieldValue('수종', treeType);
                  }
                }}
                dropdownRender={(menu) => (
                  <CustomDropdownRender
                    menu={menu}
                    input={treeType}
                    onClick={() => {
                      setTreeTypeOpen(false);
                      form.setFieldValue('수종', treeType);
                    }}
                  />
                )}
              />
            </Form.Item>
            {/* 규격 */}
            <Form.Item label='규격'>
              <Form.Item
                name='R'
                // rules={[{ required: true }]}
                style={{
                  display: 'inline-block',
                  width: isMobile ? 'calc(50% - 8px)' : 'calc(25% - 8px)',
                  marginRight: 8,
                }}
              >
                <InputNumber addonBefore='R' placeholder='30cm' />
              </Form.Item>
              <Form.Item
                name='H'
                style={{
                  display: 'inline-block',
                  width: isMobile ? 'calc(50% - 8px)' : 'calc(25% - 8px)',
                  marginRight: 8,
                }}
              >
                <InputNumber addonBefore='H' placeholder='8.0m' />
              </Form.Item>
              <Form.Item
                name='B'
                style={{
                  display: 'inline-block',
                  width: isMobile ? 'calc(50% - 8px)' : 'calc(25% - 8px)',
                  marginRight: 8,
                }}
              >
                <InputNumber addonBefore='B' placeholder='25m' />
              </Form.Item>
              <Form.Item
                name='W'
                style={{
                  display: 'inline-block',
                  width: isMobile ? 'calc(50% - 8px)' : 'calc(25% - 8px)',
                }}
              >
                <InputNumber addonBefore='W' placeholder='5.0m' />
              </Form.Item>
              <Form.Item
                name='P'
                style={{
                  display: 'inline-block',
                  width: isMobile ? 'calc(50% - 8px)' : 'calc(25% - 8px)',
                }}
              >
                <InputNumber addonBefore='P' placeholder='20cm' />
              </Form.Item>
            </Form.Item>
            {/* 수목 판매가 */}
            <Form.Item name='수목판매가' label='수목 판매가 (원)'>
              <InputNumber placeholder='9,000,000' addonBefore='₩' />
            </Form.Item>
            {/* 훈련 */}
            <Form.Item
              name='훈련여부'
              label='훈련'
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 0,
                height: 50,
              }}
              wrapperCol={{ offset: 10 }}
            >
              <Switch
                defaultChecked={initialValues?.훈련여부}
                checked={isSwitchChecked}
                onChange={onSwitchChange}
              />
            </Form.Item>
            <Form.Item name='훈련' noStyle>
              <Select
                disabled={!isSwitchChecked}
                placeholder='훈련 방법을 모두 선택해주세요'
                style={{
                  display: 'inline-block',
                  width: isMobile ? 'calc(40% - 8px)' : 'calc(70% - 8px)',
                  marginRight: 20,
                }}
                mode='multiple'
              >
                <Option value='뿌리돌림'>뿌리돌림</Option>
                <Option value='분작업'>분작업</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name='연차'
              style={{
                display: 'inline-block',
                width: isMobile ? 'calc(40% - 8px)' : 'calc(25% - 8px)',
                marginRight: 8,
              }}
            >
              <InputNumber
                disabled={!isSwitchChecked}
                addonBefore='연차'
                placeholder='4'
              />
            </Form.Item>
            {/* 수목 등급 */}
            <Form.Item name='수목등급' label='수목 등급'>
              <Select placeholder='등급을 선택해주세요'>
                <Option value='A'>A</Option>
                <Option value='B'>B</Option>
                <Option value='C'>C</Option>
                <Option value='D'>D</Option>
              </Select>
              {/* <Rate defaultValue={2} character={({ index }) => index + 1} /> */}
            </Form.Item>
            {/* 목대형태 */}
            <Form.Item name='목대형태' label='목대형태'>
              <Select placeholder='목대형태를 선택해주세요'>
                <Option value='외간'>외간</Option>
                <Option value='쌍간'>쌍간</Option>
                <Option value='다간'>다간</Option>
              </Select>
            </Form.Item>
            {/* 수형 */}
            <Form.Item name='수형' label='수형'>
              <Select
                showSearch
                placeholder='수형을 선택해주세요'
                value={treeShape}
                open={treeShapeOpen}
                onDropdownVisibleChange={(visible) => setTreeShapeOpen(visible)}
                onSearch={(value) => setTreeShape(value)}
                options={treeShapeList.map((item) => ({
                  label: item.value,
                  value: item.value,
                }))}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setTreeShapeOpen(false);
                    form.setFieldValue('수형', treeShape);
                  }
                }}
                dropdownRender={(menu) => (
                  <CustomDropdownRender
                    menu={menu}
                    input={treeShape}
                    onClick={() => {
                      setTreeShapeOpen(false);
                      form.setFieldValue('수형', treeShape);
                    }}
                  />
                )}
              />
            </Form.Item>
            {/* 기타 메모 */}
            <Form.Item name='기타메모' label='기타 메모'>
              <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
            </Form.Item>
            {/* 최근 방문일 */}
            <Form.Item name='최근방문일' label='최근 방문일'>
              <DatePicker placement='topRight' />
              {/* <Input type='date' /> */}
            </Form.Item>
            {/* 데이터 수집인 */}
            <Form.Item name='데이터수집인' label='데이터 수집인'>
              <Select
                mode='tags'
                style={{
                  width: '100%',
                }}
                options={options}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
            >
              <Button type='primary' htmlType='submit' fill={'yes'} size='xl'>
                저장하기
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </>
  );
};
export default TreeForm;
